import classNames from "classnames";
import styles from "./styles/VideoBlock.module.scss";
import {observer} from 'mobx-react';

import URI from 'urijs';
import ImageComponent from "../../../utilities/ImageComponent";
import {getCdnUrl} from "../../../../utils/SchoolBlocksUtilities";
import ErrorBlock from "../../SchoolBlocks/ErrorBlock";
import AboutBlock from "./AboutBlock";

const embedResponsiveClassName = classNames({
    [styles.embedResponsive]: true,
    [styles.embedResponsive16by9]: true,
});
const embedResponsiveItemClassName = classNames({
    [styles.embedResponsiveItem]: true,
});

export const AboutMediaInset = observer((props: {
    blockObj: IAboutTerrace,
}) => {
    let content;
    const mediaUrl = props.blockObj.embed_link as string;
    const mediaType = props.blockObj.json_data.about?.media.type as string;
    switch (mediaType) {
        case "iframe":
            if (mediaUrl) {
                content = (
                    <iframe
                        className={embedResponsiveItemClassName}
                        src={mediaUrl}
                    />
                );
            } else {
                content = <ErrorBlock blockObj={{errorMessage: "Invalid URL"}}/>
            }
            break;
        case "image":
            content = <ImageComponent
                alt={props.blockObj.json_data.about?.media.alt}
                className={embedResponsiveItemClassName}
                src={getCdnUrl(mediaUrl)}
            />
            break;
        default:
            break;
    }


    return (
        <div className={embedResponsiveClassName}>{content}</div>
    );
})
